var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - List Locations" }
      }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Locations")
                  ]),
                  _c(
                    "gov-grid-row",
                    [
                      _c(
                        "gov-grid-column",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c(
                            "ck-table-filters",
                            { on: { search: _vm.onSearch } },
                            [
                              _c(
                                "gov-form-group",
                                [
                                  _c(
                                    "gov-label",
                                    {
                                      attrs: { for: "filter[address_line_1]" }
                                    },
                                    [_vm._v("Address line 1")]
                                  ),
                                  _c("gov-input", {
                                    attrs: {
                                      id: "filter[address_line_1]",
                                      name: "filter[address_line_1]",
                                      type: "search"
                                    },
                                    model: {
                                      value: _vm.filters.address_line_1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "address_line_1",
                                          $$v
                                        )
                                      },
                                      expression: "filters.address_line_1"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "extra-filters" },
                                [
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        { attrs: { for: "filter[city]" } },
                                        [_vm._v("City")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[city]",
                                          name: "filter[city]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.city,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filters, "city", $$v)
                                          },
                                          expression: "filters.city"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        { attrs: { for: "filter[postcode]" } },
                                        [_vm._v("Postcode")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[postcode]",
                                          name: "filter[postcode]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.postcode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "postcode",
                                              $$v
                                            )
                                          },
                                          expression: "filters.postcode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm.auth.canAdd("location")
                        ? _c(
                            "gov-grid-column",
                            { attrs: { width: "one-third" } },
                            [
                              _c(
                                "gov-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    success: "",
                                    expand: ""
                                  },
                                  on: { click: _vm.onAddLocation }
                                },
                                [_vm._v("Add location")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("ck-resource-listing-table", {
                    ref: "locationsTable",
                    attrs: {
                      uri: "/locations",
                      params: _vm.params,
                      "default-sort": "address_line_1",
                      columns: [
                        {
                          heading: "Address line 1",
                          sort: "address_line_1",
                          render: function(location) {
                            return location.address_line_1
                          }
                        },
                        {
                          heading: "City",
                          sort: "city",
                          render: function(location) {
                            return location.city
                          }
                        },
                        {
                          heading: "Postcode",
                          sort: "postcode",
                          render: function(location) {
                            return location.postcode
                          }
                        }
                      ],
                      "view-route": function(location) {
                        return {
                          name: "locations-show",
                          params: { location: location.id }
                        }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }